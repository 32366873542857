* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*:focus,
*:active {
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
}
html,
body {
  height: 100%;
  width: 100%;
  place-items: center;
  font-family: "Poppins", sans-serif;
  /* overflow-x: hidden; */
}

.App {
  background: var(--backgroundFill);
}

.App > * :not(.cloudContainer) {
  z-index: 10;
}
:root {
  --cloud: rgba(255, 255, 255, 0.818);
  --cloudShading: #f1f1f1;
  --introHeading: black;
  --introSubHeading: rgb(103, 103, 103);
  --backgroundFill: #e9f3fd;
  --backgroundFill1: #ffffff;
  --backgroundFill2: #e9f3fd9c;
  --social-link: #3e454e;
  --social-link-hover: #fff;
  --skills: #db3a34;
  --projects: #ffc857;
  --home: #00dca9;
  --about: #eb5e28;
  --education: #00a8e8;
  --contentIdentifier: #4b5060;
  --contentHeading: rgb(38, 29, 29);
  --contentSubHeading: rgb(71, 60, 60);
  --copyright: rgb(91, 91, 91);
  --link: rgba(0, 0, 255, 0.654);
  --link-hover: rgba(113, 5, 113, 0.771);
  --contentHeading-small: rgb(72, 71, 71);
  --contetnParagraph: rgb(91, 91, 91);
  --contetnParagraph-light: rgba(128, 128, 128, 0.889);
  --icon-background: rgba(255, 255, 255, 0.708);
  --hover-icon: black;
  --hover-icon-hover: white;
  --hover-icon-background: rgb(255 255 255 / 90%);
  --icon-detail: black;
  --mode: gold;
  --mode-icon: #fff;
  --mode-hover: goldenrod;
  --mode-background: #ffff0063;
  --project-active: aliceblue;
  --project: rgba(164, 198, 232, 0.697);
  --tab-header: black;
  --tab-header-active: black;
}

[data-theme="dark"] {
  --cloud: #adb7c5;
  --cloudShading: #adadad;
  --introHeading: white;
  --introSubHeading: rgb(142, 142, 142);
  --backgroundFill: #010c1b;
  --backgroundFill1: #04193cb7;
  --backgroundFill2: #010c1be0;
  --social-link: #ffffffb7;
  --social-link-hover: #ffff;
  --skills: #db3a34;
  --projects: #ffc857;
  --home: #00dca9;
  --about: #eb5e28;
  --education: #00a8e8;
  --contentIdentifier: #dbe4ff;
  --contentHeading: rgb(206, 206, 206);
  --contentSubHeading: rgba(197, 189, 189, 0.857);
  --copyright: rgb(125, 125, 125);
  --link: rgb(18, 152, 182);
  --link-hover: rgba(4, 255, 201, 0.771);
  --contentHeading-small: rgb(199, 199, 199);
  --contentParagraph: rgba(172, 172, 172, 0.847);
  --contentParagraph-light: rgba(128, 128, 128, 0.889);
  --icon-background: rgb(255, 255, 255);
  --hover-icon: black;
  --hover-icon-hover: rgb(255, 255, 255);
  --hover-icon-background: rgba(255, 255, 255, 0.079);
  --icon-detail: white;
  --mode: rgb(255, 255, 255);
  --mode-icon: black;
  --mode-hover: goldenrod;
  --mode-background: rgba(255, 255, 255, 0.079);
  --project-active: rgb(88, 100, 109);
  --project: rgba(32, 39, 71, 0.83);
  --tab-header: black;
  --tab-header-active: rgb(255, 255, 255);
  --project-link-hover: white;
}

/* rest of the container */

.container {
  padding: 0 15%;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
}

.container h2 {
  font-size: 40px;
  margin: 15px 0;
  padding: 15px 0;
  color: var(--contentIdentifier);
  transition: all 0.7s ease;
}
.container p {
  color: var(--contentParagraph);
  /* color: rgb(13, 15, 17); */
  font-size: 20px;
  font-weight: 500;
  line-height: 1.75;
}

@media (width<380px) {
  .container {
    padding: 4px;
  }
}

@media (width<450px) {
  .container {
    padding: 6px;
  }
}

/* body #root .App > * {
  filter: blur(3px);
} */

body #root .App #nav {
  filter: blur(0);
}

body #root .App .mode {
  filter: blur(0);
}

.blur {
  filter: blur(9px);
}

@media (width>759px) {
  .blur {
    filter: blur(0px);
  }
}
.block {
  background-color: var(--backgroundFill2);
  border-radius: 15px;
}
