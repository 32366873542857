.education:hover h2 {
  color: var(--education);
}
.education .block {
  padding: 5px 10px 0 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
}

.education .block div {
  margin: 15px 5px;
}

.education .block h3 {
  margin: 10px 0;
}
.education .block h3 a {
  text-decoration: none;
}

.education .block h3:nth-of-type(1) {
  color: var(--contentHeading);
  font-size: 24px;
}
.education .block h3:nth-of-type(2) a {
  color: var(--link);
  font-size: 20px;
  cursor: pointer;
}

.education .block h3:nth-of-type(2) a:hover {
  color: var(--link-hover);
}

.education .block h5 {
  font-size: 15px;
  color: var(--contentHeading-small);
}

.education .block p {
  color: var(--contetnParagraph-light);
  font-size: 16px;
}

@media (width<1160px) {
  .education .block {
    justify-content: flex-start;
  }
}
