.nav {
    width: fit-content;
    align-self: flex-end;
    position: fixed;
    z-index: 15;
    right: 5px;
}
.nav .navIcons {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    padding: 15px;
    margin: 15px;
}

.nav .navIcons a {
    color: var(--hover-icon);
    text-decoration: none;
}
.nav .navIcons .icon {
    margin: 15px 0px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    border-radius: 50px;
    transition: all 0.1s ease;
}

.nav .navIcons .icon i {
    border-radius: 50%;
    margin: auto;
    padding: 16px;
    width: 50px;
    height: 50px;
    font-size: 18px;
    background-color: var(--icon-background);

    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
}
.nav .navIcons .iconDetail {
    opacity: 0;
    padding: 0px 15px;
    pointer-events: none;
    color: var(--icon-detail);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.nav .navIcons .icon:hover {
    background-color: var(--hover-icon-background);
}

.nav .navIcons .icon:hover .iconDetail {
    opacity: 100;
}

.nav .navIcons .icon:hover i {
    color: var(--hover-icon-hover);
}
.nav .navIcons .homeBtn:hover i {
    background-color: var(--home);
}
.nav .navIcons .aboutBtn:hover i {
    background-color: var(--about);
}
.nav .navIcons .educationBtn:hover i {
    background-color: var(--education);
}
.nav .navIcons .skillsBtn:hover i {
    background-color: var(--skills);
}
.nav .navIcons .projectsBtn:hover i {
    background-color: var(--projects);
}

.mode {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    position: fixed;
    z-index: 11;
    left: 5px;
    margin: 35px 25px;
    cursor: pointer;
}
/* .mode i {
  background-color: var(--mode);
  color: var(--mode-icon);
  border-radius: 50%;
  padding: 16px;
  width: 50px;
  height: 50px;
  font-size: 22px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: all 1.5s ease;
} */

.mode:hover i {
    background-color: var(--mode-hover);
}

@keyframes growShrink {
    0%,
    100% {
        box-shadow: 0 0 35px 5px yellow, 0 0 25px 10px yellow inset;
    }
    50% {
        box-shadow: 0 0 50px 20px rgba(255, 255, 0, 0.8),
            0 0 30px 20px rgb(255, 234, 0) inset;
    }
}

.mode .theme {
    width: 50px;
    height: 50px;
    box-sizing: border-box;
    padding: 12px;
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.mode .sun {
    position: absolute;
    margin: 50px 50%;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: orange;
    box-shadow: 0 0 35px 5px yellow, 0 0 25px 10px yellow inset;
    pointer-events: none;
    opacity: 0;
    transform: scale(0.6) rotate(0deg);
    transition: transform 0.3s ease-in, opacity 0.2s ease-in 0.1s;
    animation: growShrink 5s infinite;
}

.mode .sun.visible {
    pointer-events: auto;
    opacity: 1;
    transform: scale(1) rotate(180deg);
    transition: transform 0.3s ease-in, opacity 0.2s ease-in 0.1s;
}

.mode .moon {
    width: 50%;
    height: 50%;
    pointer-events: none;
    position: absolute;
    left: 12.5%;
    top: 18.75%;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 50%;
    box-shadow: 9px 3px 0px 0px #f0f0f0;
    opacity: 0;
    transform: scale(0.3) rotate(65deg);
    transition: transform 0.3s ease-in, opacity 0.2s ease-in 0.1s;
}

.mode .moon.visible {
    pointer-events: auto;
    opacity: 1;
    transform: scale(1) rotate(0deg);
    transition: transform 0.3s ease-in, opacity 0.2s ease-in 0.1s;
}

.mode .star {
    position: absolute;
    top: 25%;
    left: 5%;
    display: block;
    width: 0px;
    height: 0px;
    border-right: 7px solid rgba(0, 0, 0, 0);
    border-bottom: 5px solid #f0f0f0;
    border-left: 7px solid rgba(0, 0, 0, 0);
    transform: scale(0.55) rotate(35deg);
    opacity: 0;
    transition: all 0.2s ease-in 0.4s;
}

.mode .star:before {
    border-bottom: 5px solid #f0f0f0;
    border-left: 3px solid rgba(0, 0, 0, 0);
    border-right: 3px solid rgba(0, 0, 0, 0);
    position: absolute;
    height: 0;
    width: 0;
    top: -3px;
    left: -5px;
    display: block;
    content: "";
    transform: rotate(-35deg);
}

.mode .star:after {
    position: absolute;
    display: block;
    color: red;
    top: 0px;
    left: -7px;
    width: 0px;
    height: 0px;
    border-right: 7px solid rgba(0, 0, 0, 0);
    border-bottom: 5px solid #f0f0f0;
    border-left: 7px solid rgba(0, 0, 0, 0);
    transform: rotate(-70deg);
    content: "";
}

.mode .moon.visible .star {
    opacity: 0.8;
    /*   transform: rotate(35deg); */
}

.mode .star.small {
    transform: scale(0.35) rotate(35deg);
    position: relative;
    top: 50%;
    left: 37.5%;
    opacity: 0;
    transition: all 0.2s ease-in 0.45s;
}

.mode .moon.visible .star.small {
    opacity: 0.7;
    transform: scale(0.45) rotate(35deg);
}

.mode .modeDetail {
    opacity: 0;
    padding: 0px 15px;
    pointer-events: none;
    color: var(--icon-detail);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.mode:hover {
    border-radius: 50px;
    background-color: var(--mode-background);
}

.mode:hover .modeDetail {
    opacity: 100;
}

.nav .dropDownMenu {
    margin: 2px;
    padding: 35px 15px 10px;
    font-size: 18px;
    display: none;
    justify-content: flex-end;
    cursor: pointer;
    transition: all 0.3s ease;
}
.nav .dropDownMenu i {
    background-color: var(--icon-background);
    font-size: 18px;
    border-radius: 50%;
    padding: 16px;
    width: 50px;
    height: 50px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transition: all 1.5s ease;
}

@media (width<760px) {
    .nav .dropDownMenu {
        display: flex;
    }
    .nav .navIcons {
        margin: 2px;
        display: none;
    }
    .mode {
        display: none;
    }
}
.hover {
    position: fixed;
    width: 100%;
    height: 100%;
}
.mode.openBar {
    display: flex;
}

ul.navIcons.openBar {
    display: flex;
}

.nudge {
    position: absolute;
    border: 2px solid #4b5060;
    border-radius: 10px;
    background-color: #4b5060;
}

.nudge div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 10px 0px 0px;
}

.nudge p{
    padding: 8px;
    color: white;
    font-size: 25px;
}

.nudge i{
    background-color: transparent;
    color: white;
}

.mode .nudge {
    display: none;
    left: 0px;
    top: 70px;
    width: 250px;
}

.nav .nudgenav {
    display: none;
    right: 25px;
    bottom: -85px;
    width: 275px;
}

.nav .nudgemenu {
    display: none;
    right: 100px;
    top: 40px;
    width: 275px;
}

.nav .nudgemenu:after {
    content: "";
    display: block;
    position: absolute;
    top: 10px;
    right: -13px;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 12px solid #4b5060;
    border-right: none;
    margin: 0;
}

.mode .nudge:after {
    content: "";
    display: block;
    position: absolute;
    top: -13px;
    left: 10px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #4b5060;
    border-top: none;
    margin: 0;
}

.nav .nudgenav:after {
    content: "";
    display: block;
    position: absolute;
    top: -13px;
    right: 15px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #4b5060;
    border-top: none;
    margin: 0;
}
